
import { Component, Vue } from "vue-property-decorator";
import { UserProfileData } from "@/models/entities/user-profile-data.interface";
import { userService } from "@/services/api/users.service";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import OverlayModule from "@/store/modules/overlay.module";

const overlayModule = getModule(OverlayModule, store);

@Component
export default class ResPersonalInfo extends Vue {
  private profile: UserProfileData = {} as UserProfileData;

  private get userInfo(): string {
    if (this.profile.isPrivate)
      return `${this.profile.firstName} ${this.profile.lastName}`;
    return `${this.profile.businessName} - P.IVA: ${this.profile.vatNumber}`;
  }

  private get address(): string {
    return `${this.profile.address}, ${this.profile.city}, ${this.profile.province} ${this.profile.postalCode}`;
  }

  private created(): void {
    this.getProfileData();
  }

  private async getProfileData(): Promise<void> {
    overlayModule.showOverlay();
    const u = await userService.getProfileData();
    this.profile = u.data;
  }

  private openMailClient(): void {
    window.open(
      "mailto: Leccolatte.commerciale@gmail.com?subject=Richiesta di modifica dei dati personali"
    );
  }

  private navHome(): void {
    this.$router.push({ name: "home" });
  }
}
