import { UserProfileData } from "@/models/entities/user-profile-data.interface";
import { User } from "@/models/entities/user.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "user";

class UsersService extends ApiService {
  getAllUsers(isDeleted?: boolean) {
    return this.getAll<User[]>(CONTROLLER, {
      params: {
        deleted: isDeleted,
      },
    });
  }
  getUserByID(id: number) {
    return this.getByID<number, User>(CONTROLLER, id);
  }
  saveUser(User: User) {
    return this.post<User, number>(CONTROLLER, User);
  }
  reactivateUser(id: number) {
    return this.update<number, void>(`${CONTROLLER}/${id}/reactivate`, null);
  }
  updateUser(id: number, user: User) {
    return this.updateById<number, User, void>(CONTROLLER, id, user);
  }
  deleteUser(id: number) {
    return this.delete<number, void>(CONTROLLER, id);
  }
  deletePermanently(id: number) {
    return this.deleteAlternative<number, void>(
      `${CONTROLLER}`,
      id,
      "permanently"
    );
  }
  getProfileData() {
    return this.getAll<UserProfileData>(`${CONTROLLER}/profile`);
  }
}

export const userService = new UsersService();
